@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: "Pretendard-Regular", sans-serif;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff2")
      format("woff2");
  font-weight: 400;
  font-style: normal;
} */

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

body {
  margin: 0;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* modal창 */
.modalBody {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  /* overflow: auto; */
}

/* modal창 스크롤바 */
/* .modalBody::-webkit-scrollbar {
  width: 10px;
}
.modalBody::-webkit-scrollbar-thumb {
  background-color: #2fb796;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.modalBody::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
} */

#modalCloseBtn {
  position: absolute;
  top: 2rem;
  right: 3rem;
  border: none;
  background-color: transparent;
  font-size: 30px;
}

#modalCloseBtn:hover {
  cursor: pointer;
}

/* 제출버튼 비활성화 / 활성화 */

a {
  text-decoration-line: none !important;
}

/* @font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.ttf")
      format("truetype");
  font-display: swap;
} */

/* 
.carousel button:not(:first-child) {
  margin-left: 0.5rem;
} */

div.carousel-indicators .active {
  background-color: #6885f8 !important;
}

.carousel-control-prev {
  margin-left: -100px;
}

.carousel-control-next {
  margin-right: -100px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 47px !important;
  height: 73px !important;
}

.carousel-indicators {
  top: 720px;
}

.carousel button {
  width: 200px !important;
  height: 6px !important;
  background-color: #eeeeee !important;
}
